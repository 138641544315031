import styled from 'styled-components';
import { TypeParagraph } from '../../common/typography/TypeParagraph';

export const Container = styled.div`
  display: block;
  width: 100vw;
  height: 100vh;
  position: relative;
`;

export const CopyrightWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`

export const Copyright1 = styled(TypeParagraph)`
  font-family: ${props => props.theme.font.helveticaNeueLight};
`;

export const ContactWrapper = styled.div`
  text-align: center;
  margin-bottom: 12px;
`;

export const ContactCopy = styled(TypeParagraph)`
  font-family: ${props => props.theme.font.helveticaNeueLight};
  text-transform: uppercase;
`;

export const AddressCopy = styled(TypeParagraph)`
  margin-bottom: 12px;
  font-family: ${props => props.theme.font.cardinalRegular};
  text-transform: uppercase;
  display: block;
`;

export const BottomWrapper = styled.div`
  position: absolute;
  bottom: 6vh;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
`

export const SocialWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

export const SocialLink = styled(TypeParagraph)`
  pointer-events: auto;
  font-family: ${props => props.theme.font.helveticaNeueLight};
  text-transform: uppercase;
  &:hover {
    text-decoration: underline;
  }
`
