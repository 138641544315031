import React, { forwardRef, useState } from "react"
import { TypeHeadingSizes } from '../../common/typography/TypeHeading';
import { TypeParagraphSizes } from '../../common/typography/TypeParagraph';
import { Container, Content,Title, Subtitle, Info, InfoWrapper, Small, InfoLabel, Cover } from './styled';

export interface ProjectHeroProps { className?: string, project: any };

const ProjectHero = forwardRef((props: ProjectHeroProps, ref) => {

  const getCategories = () => {
    let label = '';
    props.project.categories.forEach(({ Name }) => {
      label += `${Name}, `
    })
    label = label.slice(0, -2);
    return label;
  }

  const CoverData = {
    URL: null,
    Upload: {
      ...props.project.Cover
    }
  }

  return (
    <Container className={props.className}>
      <Content>
        <Title size={TypeHeadingSizes.H1}>
          {props.project.Title}
        </Title>
        <Subtitle size={TypeHeadingSizes.H3}>{props.project.Subtitle}</Subtitle>
        <Info>
          <InfoWrapper>
            <Small size={TypeParagraphSizes.XS}>
              Categories
            </Small>
            <InfoLabel size={TypeParagraphSizes.S}>{getCategories()}</InfoLabel>
          </InfoWrapper>
          <InfoWrapper>
            <Small size={TypeParagraphSizes.XS}>
              Published
            </Small>
            <InfoLabel size={TypeParagraphSizes.S}>2020</InfoLabel>
          </InfoWrapper>
        </Info>
      </Content>
      <Cover data={CoverData} />
    </Container>
  )
})

export default ProjectHero;
