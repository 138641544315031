import React, { useEffect } from "react"
import '../../util/i18n';
import { GlobalStyle, HeaderContainer } from './styled';
import Fonts from 'src/components/common/typography/Fonts'
import { ThemeProvider } from 'styled-components';
import theme from '../../data/styles/themes/default'
import WebGLApplication from '../organisms/WebGLApplication';

export const ThemeContext = React.createContext(theme);

export default function Layout({ children }) {

  return (
    <React.Fragment>
      <ThemeContext.Provider value={theme}>
        <ThemeProvider theme={theme}>
          <WebGLApplication />
          <GlobalStyle />
          <Fonts />
          <HeaderContainer />
          {children}
        </ThemeProvider>
      </ThemeContext.Provider>
    </React.Fragment>
  )
}
