import { createGlobalStyle } from 'styled-components';

import CardinalItalicWoff from 'src/assets/fonts/cardinal/cardinalfruitweb-italic.woff';
import CardinalItalicWoff2 from 'src/assets/fonts/cardinal/cardinalfruitweb-italic.woff2';
import CardinalRegularWoff from 'src/assets/fonts/cardinal/cardinalfruitweb-regular.woff';
import CardinalRegularWoff2 from 'src/assets/fonts/cardinal/cardinalfruitweb-regular.woff2';
import HelveticaNeueLightWoff from 'src/assets/fonts/helvetica-neue/HelveticaNeueCyr-Light.woff';
import HelveticaNeueLightWoff2 from 'src/assets/fonts/helvetica-neue/HelveticaNeueCyr-Light.woff2';
import HelveticaNeueRegularWoff from 'src/assets/fonts/helvetica-neue/HelveticaNeueCyr-Roman.woff';
import HelveticaNeueRegularWoff2 from 'src/assets/fonts/helvetica-neue/HelveticaNeueCyr-Roman.woff2';


export default createGlobalStyle`
  @font-face {
    font-family: ${props => props.theme.font.cardinalItalic};
    src: url(${CardinalItalicWoff2}) format('woff2'),
    url(${CardinalItalicWoff}) format('woff');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: ${props => props.theme.font.cardinalRegular};
    src: url(${CardinalRegularWoff2}) format('woff2'),
    url(${CardinalRegularWoff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: ${props => props.theme.font.helveticaNeueLight};
    src: url(${HelveticaNeueLightWoff2}) format('woff2'),
    url(${HelveticaNeueLightWoff}) format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: ${props => props.theme.font.helveticaNeueRegular};
    src: url(${HelveticaNeueRegularWoff2}) format('woff2'),
    url(${HelveticaNeueRegularWoff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
`;
