import { TypeHeadingSizes } from '../../../components/common/typography/TypeHeading';
import { TypeParagraphSizes } from '../../../components/common/typography/TypeParagraph';
import { Breakpoint } from '../Breakpoint';
import mixins from '../../../util/style/mixins';
import config from '../../../../client-env';

export default {
  urls: {
    assetsBase: () => {
      return config.API_URL;
    }
  },
  font: {
    cardinalItalic: 'CardinalItalic',
    cardinalRegular: 'CardinalRegular',
    helveticaNeueRegular: 'HelveticaNeueRegular',
    helveticaNeueLight: 'HelveticaNeueLight',
  },
  mixins: {
    ...mixins,
  },
  layers: {
    header: 2,
    content: 1,
    background: 0,
  },
  color: {
    // Core colors
    white: '#fff',
    black: '#000',
    gradient: (angle = 35) => `linear-gradient(${angle}deg, #00e487, #1ecbd0)`,
  },
  spacing: {
    SPACING_1: 7,
    SPACING_2: 2,
  },
  headings: {
    [Breakpoint.XS]: {
      [TypeHeadingSizes.H1]: 34,
      [TypeHeadingSizes.H2]: 34,
      [TypeHeadingSizes.H3]: 20,
      [TypeHeadingSizes.H4]: 24,
    },
    [Breakpoint.M]: {
      [TypeHeadingSizes.H1]: 150,
      [TypeHeadingSizes.H2]: 145,
      [TypeHeadingSizes.H3]: 78,
      [TypeHeadingSizes.H4]: 68,
    },
  },
  paragraphs: {
    [Breakpoint.XS]: {
      [TypeParagraphSizes.XS]: 14,
      [TypeParagraphSizes.S]: 16,
      [TypeParagraphSizes.M]: 18,
      [TypeParagraphSizes.L]: 20,
      [TypeParagraphSizes.XL]: 32,
    },
    [Breakpoint.M]: {
      [TypeParagraphSizes.XS]: 18,
      [TypeParagraphSizes.S]: 20,
      [TypeParagraphSizes.M]: 24,
      [TypeParagraphSizes.L]: 28,
      [TypeParagraphSizes.XL]: 32,
    },
  },
} as const;
