import React, { forwardRef, useRef, useImperativeHandle, useState } from "react"
import { TypeParagraph, TypeParagraphSizes } from '../../common/typography/TypeParagraph';

const AboutPage = forwardRef((props: any, ref) => {
  return (
    <>
      <TypeParagraph
        size={TypeParagraphSizes.XL}
      >About Page</TypeParagraph>
    </>
  )
})

export default AboutPage;
