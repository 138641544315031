import React, { forwardRef, useRef, useImperativeHandle } from "react"
import { TypeParagraphSizes } from '../../common/typography/TypeParagraph';
import { SocialWrapper, Container, Copyright1, CopyrightWrapper, BottomWrapper, ContactWrapper, ContactCopy, AddressCopy, SocialLink } from './styled';

export interface FooterProps { className?: string };

const Footer = forwardRef((props: FooterProps, ref) => {

  const copyright = '© U.I.WD. 2013 — 2021';
  const telephone = '+ 55 11 5181 1171  —  ';
  const email = 'hello@uiwd.co';
  const address = 'Rua Cancioneiro de Evora, 190. SAo Paulo. SP. Brazil.';
  const social = [
    {
      name: 'Tumblr',
      url: '#'
    },
    {
      name: 'Instagram',
      url: '#'
    },
    {
      name: 'Linkedin',
      url: '#'
    }
  ]

  const renderSocial = social.map((s, i) => {
    return <SocialLink target="_blank" key={i} size={TypeParagraphSizes.S} as={'a'} href={s.url}>{s.name}</SocialLink>
  })

  return (
    <Container className={props.className}>
      <CopyrightWrapper>
        <Copyright1 size={TypeParagraphSizes.S}>{copyright}</Copyright1>
      </CopyrightWrapper>

      <BottomWrapper>
        <ContactWrapper>
          <ContactCopy size={TypeParagraphSizes.S}>{telephone}</ContactCopy>
          <ContactCopy size={TypeParagraphSizes.S}>{email}</ContactCopy>
        </ContactWrapper>
        <AddressCopy size={TypeParagraphSizes.S}>{address}</AddressCopy>
        <SocialWrapper>
          {renderSocial}
        </SocialWrapper>
      </BottomWrapper>
    </Container>
  )
})

export default Footer;
