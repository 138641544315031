import gsap from 'gsap';
import { Mesh, MeshBasicMaterial, Object3D, PlaneGeometry } from 'three';
import Image3D from './Image3D';
import happens from 'happens';
import IndexCover from './IndexCover';
import IndexThumbnail from './IndexThumbnail';
import IAsset from '../../interface/IAsset';
import planeGeometry from './geometry/plane';
import { getCurrentMinWidth } from '../../util/respondTo';
import { Breakpoint } from '../../data/styles/Breakpoint';

export default class IndexPlane {
  private app: any;
  private images: Array<IAsset> = [];
  private thumbs = [];
  public mesh: Object3D;
  public hitarea: any;
  private cover: IndexCover;
  public x: number;
  public y: number;
  public radius: number;
  public resolution: number;
  public scale: number;
  private transitioned: boolean = false;
  public emit;
  public on;
  public loaded: boolean = false;
  private data: any;
  private hitAreaThumbs: any;

  constructor(data: any, app: any) {
    this.over = this.over.bind(this);
    this.out = this.out.bind(this);
    this.clicked = this.clicked.bind(this);
    this.resolution = getCurrentMinWidth() < Breakpoint.L ? 320 : 1920;
    this.radius = 3 * (window.innerWidth / this.resolution);
    this.app = app;
    this.data = data;
    this.images = data.images;
    this.mesh = new Object3D();
    this.cover = new IndexCover(data.images[0], this.app);
    this.addThumbs();
    this.addHitArea();
    this.mesh.scale.set(0.4,0.4,0.4)
    this.mesh.add(this.cover.mesh);
    happens(this);
    this.hitAreaThumbs = [...this.thumbs, this.cover]
  }

  private addThumbs() {
    const angle = this.images.length / (Math.PI / 2)
    let total = Math.max(2, this.images.length);
    total = Math.min(4, total);
    this.images.map((img, i) => {
      if (i > 0 && i <= total) {
        const thumb = new IndexThumbnail(img, this.app, (angle * i) * (0.5 + Math.random()), this.cover)
        thumb.mesh.position.z -= 0.1;
        this.mesh.add(thumb.mesh);
        this.thumbs.push(thumb);
      }
    })
  }

   private addHitArea() {
    const geo = planeGeometry(1,1);
    const mat = new MeshBasicMaterial({ color: 0x00FF00, transparent: true });
    mat.opacity = 0;
    this.hitarea = new Mesh(geo, mat);
    this.mesh.add(this.hitarea);
  }

  private scaleHitArea() {
    let x1 = 0;
    let x2 = 0;
    let y1 = 0;
    let y2 = 0;
    this.hitAreaThumbs.map((t) => {
      if (x1 > t.image.mesh.position.x) {
        x1 = t.image.mesh.position.x
      }
      if (x2 < t.image.mesh.position.x) {
        x2 = t.image.mesh.position.x
      }
      if (y1 > t.image.mesh.position.y) {
        y1 = t.image.mesh.position.y
      }
      if (y2 < t.image.mesh.position.y) {
        y2 = t.image.mesh.position.y
      }
    })
    const width = Math.abs(x2 - x1);
    const height = Math.abs(y2 - y1);
    this.hitarea.scale.x = width;
    this.hitarea.scale.y = height;
    this.hitarea.position.x = 0
    this.hitarea.position.y = -height/2

  }

  public dispose() {
    this.loaded = false;
    this.transitioned = false;
    this.mesh.scale.set(0.4,0.4,0.4)
    this.mesh.position.x = 0;
    this.mesh.position.y = 0;
    this.cover.dispose();
    this.thumbs.map((t) => t.dispose())
  }

  public preload() {
    return this.cover.preload(() => {
      this.loaded = true;
      this.emit('load');
    })
  }

  private addEvents() {
    (this.hitarea as any).cursor = 'pointer';
    (this.hitarea as any).off('mouseover', this.over);
    (this.hitarea as any).on('mouseover', this.over);
    (this.hitarea as any).off('mouseout', this.out);
    (this.hitarea as any).on('mouseout', this.out);
    (this.hitarea as any).off('click', this.clicked);
    (this.hitarea as any).on('click', this.clicked);
    (this.hitarea as any).off('touchdown', this.clicked);
    (this.hitarea as any).on('touchdown', this.clicked);
    this.scaleHitArea();
  }

  private over() {
    this.emit('over', this.data)
    this.cover.overAnimation(this.x);
    this.thumbs.map((t, i) => {
      t.overAnimation(this.x, i / 20)
    })
  }

  private clicked() {
    this.emit('clicked', this.data)
  }

  private out() {
    this.emit('out')
    this.cover.outAnimation(0);
    this.thumbs.map((t, i) => {
      t.outAnimation(0)
    })
  }


  public update() {
    this.scaleHitArea();
    this.thumbs.map((t, i) => {
      t.update();
    })
    this.cover.update();
  }

  public resize(viewport) {
    if (this.transitioned) {
      this.mesh.position.x = this.x
      this.mesh.position.y = this.y
    }
    this.cover.resize(viewport);
    this.scaleHitArea();
  }

  public transitionIn() {
    const scale = { value: 0.4 }
    const tl = gsap.timeline({
      onStart: () => {
        this.transitioned = true;
        this.thumbs.map((t) => t.preload())
      },
      onComplete: () => {
        this.addEvents();
      }
    });
    tl.to(scale, { value: 1, onUpdate: () => {
      this.mesh.scale.set(scale.value,scale.value,scale.value)
    } }, 0);
    tl.add(this.cover.transitionIn(), 0)
    tl.fromTo(this.mesh.position, {x: 0, y: 0}, { duration: 1.5, ease: 'power4.out', x: this.x, y: this.y }, 0);
    return tl;
  }

  get height() {
    return this.cover.height;
  }
}
