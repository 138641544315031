import React, { forwardRef } from "react"
import { Container, Column, Asset1, Asset2, Asset3 } from './styled';

export interface AssetThreeProps { className?: string, data: any };

const AssetThree = forwardRef((props: AssetThreeProps, ref) => {

  return (
    <Container className={props.className}>
      <Column>
        <Asset1 data={props.data.Asset1}></Asset1>
        <Asset2 data={props.data.Asset2}></Asset2>
      </Column>
      <Asset3 data={props.data.Asset3}></Asset3>
    </Container>
  )
})

export default AssetThree;
