import styled from 'styled-components';
import { Breakpoint } from '../../../data/styles/Breakpoint';
import { minWidth } from '../../../util/style/mixins/respondTo';
import { TypeHeading } from '../../common/typography/TypeHeading';
import { TypeParagraph } from '../../common/typography/TypeParagraph';
import Asset from '../../molecules/Asset';

export const Container = styled.div`
  display: block;
  margin-bottom: 14rem;
  ${minWidth(Breakpoint.M)} {
    margin-bottom: 28rem;
  }
`;

export const Content = styled.div`
  width: 80%;
  margin: 0 auto;
  text-align: center;
  padding-top: 17vw;
  margin-bottom: 15vw;
`

export const Cover = styled(Asset)`
  width: 55vw;
  margin: 0 auto;
  ${minWidth(Breakpoint.M)} {
    width: 55%;
  }
`

export const Title = styled(TypeHeading)`
  text-transform: uppercase;
  font-family: ${props => props.theme.font.helveticaNeueRegular};
  display: block;
  margin-bottom: 3vw;
`

export const Subtitle = styled(TypeHeading)`
  font-family: ${props => props.theme.font.cardinalRegular};
  width: 80%;
  display: block;
  margin: 0 auto;
  text-align: center;
  line-height: 120%;
  margin-bottom: 5vw;
`

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const InfoWrapper = styled.div`
`

export const Small = styled(TypeParagraph)`
  font-family: ${props => props.theme.font.cardinalItalic};
  opacity: 0.7;
  letter-spacing: 0.5px;
  margin-right: 15px;
`

export const InfoLabel = styled(TypeParagraph)`
  text-transform: uppercase;
  font-family: ${props => props.theme.font.helveticaNeueLight};
`
