import styled from 'styled-components';
import Asset from '../../molecules/Asset';

export const Container = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
`;

export const Asset1 = styled(Asset)`
  width: 55%;
`;

export const Asset2 = styled(Asset)`
  width: 44%;
  transform: translateX(-100%) translateY(-30%);
`;
