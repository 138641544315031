import styled from 'styled-components';
import Asset from '../../molecules/Asset';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12%;
`

export const Asset1 = styled(Asset)`
  width: 24%;
  margin-left: 10%;
`;

export const Asset2 = styled(Asset)`
  width: 49%;
  margin-right: 10%;
`;
export const Asset3 = styled(Asset)`
  width: 32%;
`;
