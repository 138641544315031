import styled from 'styled-components';
import { Breakpoint } from '../../../data/styles/Breakpoint';
import { minWidth } from '../../../util/style/mixins/respondTo';
import Asset from '../../molecules/Asset';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 14rem;
  ${minWidth(Breakpoint.M)} {
    margin-bottom: 28rem;
  }
`;

export const LeftColum = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const RightColum = styled.div`
  width: 45%;
`

export const Asset1 = styled(Asset)`
  margin-bottom: 14%;
  margin-top: 14%;
  width: 100%;
`

export const Asset2 = styled(Asset)`
  margin-bottom: 30%;
  margin-left: -4%;
`

export const Asset3 = styled(Asset)`
  width: 88%;
`

export const Asset4 = styled(Asset)`
  margin-left: -3.5%;
  width: 81%;
`
