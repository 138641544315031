import LocomotiveScroll from 'locomotive-scroll';

class Scroller {
  private scroller: any;
  private element: HTMLElement;
  private resizeObserver: any;
  private readyPromise;

  public init(el): void {
    this.element = el;
    this.scroller = new LocomotiveScroll({
      el: this.element,
      smooth: true,
      smartphone: {
        smooth: true,
      },
      tablet: {
        smooth: true,
      }
    })
    this.resizeObserver = new (window as any).ResizeObserver(() => {
      this.scroller.scrollTo('top')
      this.scroller.update();
    });
    this.resizeObserver.observe(this.element);
    if (this.readyPromise) {
      this.readyPromise();
      this.readyPromise = undefined;
    }
  }

  public ready() {
    return new Promise((resolve) => {
      this.readyPromise = resolve;
      if (this.scroller) {
        this.readyPromise();
        this.readyPromise = undefined;
      }
    })
  }

  destroy() {
    this.resizeObserver.unobserve(this.element);
    this.resizeObserver.disconnect();
    this.scroller.destroy();
  }

  get scroll() {
    return this.scroller;
  }
}

export default new Scroller;
