import React, { useEffect, useRef } from "react"
import AboutPage from '../components/pages/about'
import IndexPage from '../components/pages/index'
import CasePage from '../components/pages/case'
import { Container } from './styled';
import { graphql, useStaticQuery } from 'gatsby';

export default function Layout(props: any) {

  const data = useStaticQuery(graphql`
    query MyQuery {
      allStrapiProjects {
        edges {
          node {
            Title
            Slug
            Subtitle
            Cover {
              formats {
                small {
                  url
                  width
                  height
                }
                large {
                  width
                  height
                  url
                }
              }
            }
            Component {
              strapi_component
              Title
              Subtitle
              List {
                Name
                Value
              }
              Description
              Asset1 {
                Video {
                  URL
                  Width
                  Height
                }
                Upload {
                  formats {
                    small {
                      width
                      height
                      url
                    }
                    large {
                      width
                      height
                      url
                    }
                  }
                }
              }
              Asset2 {
                Video {
                  URL
                  Width
                  Height
                }
                Upload {
                  formats {
                    small {
                      width
                      url
                      height
                    }
                    large {
                      width
                      url
                      height
                    }
                  }
                }
              }
              Asset3 {
                Video {
                  URL
                  Width
                  Height
                }
                Upload {
                  formats {
                    small {
                      width
                      url
                      height
                    }
                    large {
                      width
                      url
                      height
                    }
                  }
                }
              }
              Asset4 {
                Video {
                  URL
                  Width
                  Height
                }
                Upload {
                  formats {
                    small {
                      width
                      url
                      height
                    }
                    large {
                      width
                      url
                      height
                    }
                  }
                }
              }
            }
            categories {
              Name
              Slug
            }
          }
        }
      }
    }

  `)

  return (
    <Container>
      {props.about &&
        <AboutPage data={data}></AboutPage>
      }
      {props.index &&
        <IndexPage data={data}></IndexPage>
      }
      {props.case &&
        <CasePage data={data} slug={props.case}></CasePage>
      }
    </Container>
  )
}
