import React, { forwardRef, useRef, useImperativeHandle, useState, useEffect, useContext } from "react"
import IIndexProject from '../../../interface/IIndexProject';
import { Container, Img } from './styled';
import IAsset from '../../../interface/IAsset';
import { ThemeContext } from '../../Layout/Layout'

export interface ProjectListingScrollItemProps { className?: string, project: IIndexProject, theme?: any };

const ProjectListingScrollItem = forwardRef((props: ProjectListingScrollItemProps, ref) => {
  const theme = useContext(ThemeContext)
  const el = useRef<any>();
  const images:Array<any> = [];

  useImperativeHandle(ref, () => ({
    preload() {
      return new Promise((resolve: Function) => {
        props.project.images.forEach((img: IAsset, index: number) => {
          if (index === 0) {
            el.current.style.width = `${img.formats.small.width * 0.8}px`;
            el.current.style.height = `${img.formats.small.height * 0.8}px`;
          }
        })
      })
    },
  }));

  const renderImages = props.project.images.map((img: IAsset, i: number) => {
    images.push(useRef());
    const scale = Math.min(Math.random(), 0.5)
    if (i === 0) {
      return <Img cover className='image' ref={images[i]} key={i} src={`${theme.urls.assetsBase()}${img.formats.small.url}`} />
    } else {
      return <Img scale={scale} className='image' ref={images[i]} key={i} src={`${theme.urls.assetsBase()}${img.formats.small.url}`} />
    }
  })


  return (
    <Container ref={el} data-scroll className={props.className}>
      {renderImages}
    </Container>
  )
})

export default ProjectListingScrollItem;
