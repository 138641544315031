import { navigate } from "gatsby"
import gsap from 'gsap';
import happens from 'happens';
import { Object3D, PerspectiveCamera, Scene, WebGLRenderer } from 'three';
import { Breakpoint } from '../../data/styles/Breakpoint';
import IIndexProject from '../../interface/IIndexProject';
import IndexPlane from './IndexPlane';
import scroller from 'src/util/scroller';
import Image3D from './Image3D';
import WebGLFooter from './WebGLFooter';

export default class WebGLIndexPage {
  private app: any;
  private scene: Scene;
  private camera: PerspectiveCamera;
  private renderer: WebGLRenderer;
  private projectList: Array<any> = []
  private logo: Image3D;
  private footer: WebGLFooter;
  private mesh: Object3D;
  private layout: {
    horizontalMargin: number
    height: number
  } = {
    horizontalMargin: 15,
    height: 0
  }
  public on: Function;
  public emit: Function;
  private active: boolean = false;

  constructor(app: any) {
    this.projectOver = this.projectOver.bind(this)
    this.projectOut = this.projectOut.bind(this)
    this.projectClicked = this.projectClicked.bind(this)
    this.app = app;
    this.mesh = new Object3D();
    this.scene = this.app.scene;
    this.camera = this.app.camera;
    this.renderer = this.app.renderer;
    happens(this);
  }

  public initialize(data) {
    data.map((element: IIndexProject, i: number) => {
      const project = new IndexPlane(element, this.app);
      project.on('over', this.projectOver);
      project.on('out', this.projectOut);
      project.on('clicked', this.projectClicked);
      this.mesh.add(project.mesh);
      this.projectList.push(project);
    });
    this.logo = new Image3D('/logo.jpg', this.app.viewport, true);
    this.logo.mesh.position.z -= 0.09;
    this.mesh.add(this.logo.mesh);
    this.scene.add(this.mesh);
    this.addEvents();
    this.addFooter()
  }

  private projectClicked(project) {
    navigate(`/case/${project.slug}`)

  }

  private projectOver(project) {
    console.log('ovverer')
    this.emit('project:over', project)
  }

  private projectOut() {
    this.emit('project:out')
  }

  private preloadLogo(cb) {
    this.logo.preload(() => {
      gsap.to(this.logo, { alpha: 1, duration: 2, ease: 'power4.inOut', onComplete: cb })
      gsap.to(this.logo, { alpha: 0, duration: 1, ease: 'power4.inOut', delay: 3 })
    });
  }

  private addFooter() {
    this.footer = new WebGLFooter(this.app);
    this.footer.mesh.position.y = -this.app.viewport.height * 2;
    this.mesh.add(this.footer.mesh);
  }

  public update() {
    if (!this.active) return;
    this.projectList.map(project => project.update());
    if (this.footer) {
      this.footer.update();
    }
  }

  private async addEvents() {
    await scroller.ready();
    scroller.scroll.on('scroll', (scroll) => {
      const percent = scroll.scroll.y / scroll.limit.y;
      this.mesh.position.y = -percent * this.layout.height
    })
  }

  private positionItems(): void {
    let col: number = 0;
    let row: number = 0;
    let margin;
    this.projectList.map((project: IndexPlane, i: number) => {
      const prev = i > 0 ? this.projectList[i - 1] : false;
      if (col === 0) {
        margin = this.layout.horizontalMargin;
      } else {
        margin = -this.layout.horizontalMargin;
      }
      project.x = this.app.viewport.left + (col * this.app.viewport.width) + margin;
      if (prev) {
        project.y = prev.y - (prev.height + 3);
      } else {
        project.y = this.app.viewport.top;
      }
      project.resize(this.app.viewport);
      this.layout.height = project.y - project.height;
      row += 1;
      col += 1;
      if (col === 2) {
        col = 0;
      }
    })
    if (this.footer) {
      this.footer.mesh.position.y = this.layout.height - (this.app.viewport.top * 2);
      this.layout.height -= this.app.viewport.height;
    }
  }

  public resize(): void {
    this.positionItems()
    if (this.footer) {
      this.footer.resize();
    }
  }

  public onBreakpointChange(breakpoint: number): void {
    if (breakpoint < Breakpoint.M) {
      this.layout.horizontalMargin = .20 * this.app.viewport.width;
    } else {
      this.layout.horizontalMargin = .20 * this.app.viewport.width;
    }
    this.resize();
  }

  private preload(cb) {
    const preloadTimeline = gsap.timeline({ paused: true, onComplete: () => {
      cb();
    } });

    const next = () => {
      preloadTimeline.play()
    }

    const checkAssetComplete = (i) => {
      if (i >= this.projectList.length - 1) return;
      if (this.projectList[i + 1].loaded) {
        next();
      } else {
        this.projectList[i + 1].on('load', next);
      }
    }

    this.projectList.map((p, i) => {
      const tl = gsap.timeline({
        onComplete: () => {
          checkAssetComplete(i)
        }
      })
      tl.add(p.preload());
      preloadTimeline.add(tl, i / 10)
    })
    preloadTimeline.play()
  }

  public transitionIn(): void {
    this.active = true;
    this.mesh.visible = true;
    this.preloadLogo(() => {
      console.log('logo preloaded')
      this.preload(() => {
        console.log('preload')
        this.positionItems()
        const timeline = gsap.timeline({ paused: true })
        this.projectList.map((p, i) => {
          console.log("ADD TRANSITION COVER");
          timeline.add(p.transitionIn(), i * 0.1)
        })
        timeline.restart();
      });
    })
  }

  public transitionOut(): void {
    this.active = false;
    this.mesh.visible = false;
    this.projectList.map((p, i) => {
      p.dispose();
    });
  }
}
