import { DoubleSide, Mesh, MeshBasicMaterial, Object3D, TextureLoader } from 'three';
import cylinderGeometry from '../webgl/geometry/cylinder';

export default class WebGLFooter {
  private app: any;
  private viewport: any;
  private material: any;
  public mesh: any;
  public mesh2: any;
  public mesh1: any;
  public rotation1: any;
  public rotation2: any;
  public mouse: any;

  constructor(app) {
    this.onMouseMove = this.onMouseMove.bind(this);
    this.app = app;
    this.viewport = this.app.viewport;
    this.mesh = new Object3D();
    this.mesh1 = new Object3D();
    this.rotation1 = new Object3D();
    this.mesh2 = new Object3D();
    this.rotation2 = new Object3D();
    const hratio = 0.5 / 1.3;
    const geometry = cylinderGeometry(1, 1 * hratio);
    const texture = new TextureLoader().load( "/footer.png" );
    this.material = new MeshBasicMaterial({
      map: texture,
      side: DoubleSide,
      transparent: true,
    })
    this.mouse = { x: 0, y: 0 };
    this.mesh1 = new Mesh(geometry, this.material);
    this.mesh2 = new Mesh(geometry, this.material);
    this.rotation1.add(this.mesh1);
    this.rotation2.add(this.mesh2);
    this.mesh.add(this.rotation1);
    this.mesh.add(this.rotation2);
    this.mesh.position.z = -0.5;
    this.resize();
    document.addEventListener('mousemove', this.onMouseMove, false);
  }

  onMouseMove(event) {
    event.preventDefault();
    this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
  }

  update() {
    // this.rotation1.rotation.x = this.mouse.y;
    this.rotation1.rotation.z = this.mouse.x;
    // this.rotation2.rotation.x = -this.mouse.y;
    this.rotation2.rotation.z = this.mouse.x;
    this.mesh1.rotation.y += 0.03;
    this.mesh2.rotation.y += 0.03;
  }

  resize() {
    this.rotation1.scale.x = this.viewport.width * 0.25;
    this.rotation1.scale.y = this.viewport.width * 0.25;
    this.rotation1.position.x = this.viewport.left + this.mesh1.scale.x;
    this.rotation2.scale.x = this.viewport.width * 0.25;
    this.rotation2.scale.y = this.viewport.width * 0.25;
    this.rotation2.position.x = this.viewport.left + this.viewport.width - this.mesh1.scale.x
  }
}
