import React, { forwardRef, useRef, useImperativeHandle } from "react"
import { useTranslation } from 'react-i18next';
import { TypeParagraphSizes } from '../../common/typography/TypeParagraph';
import { Container, FilterBy, FilterContainer, MenuContainer, SortBy } from './styled';

export interface HeaderProps { className?: string };

const Header = forwardRef((props: HeaderProps, ref) => {
  const { t, i18n } = useTranslation();

  return (
    <Container className={props.className}>
      <FilterContainer>
        <SortBy size={TypeParagraphSizes.XS} as='p'>{t('sortBy')}</SortBy>
        <FilterBy />
      </FilterContainer>
      <MenuContainer />
    </Container>
  )
})

export default Header;
