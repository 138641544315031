import { PerspectiveCamera, Scene, WebGLRenderer } from 'three';
import happens from 'happens';
import { Interaction } from 'three.interaction';
import WebGLIndexPage from './WebGLIndexPage';

interface IViewport {
  width: number
  height: number
  top: number
  left: number
}

class WebGLApp {

  public camera: PerspectiveCamera;
  public scene: Scene;
  public renderer: WebGLRenderer;
  public viewport: IViewport;
  public indexView: WebGLIndexPage;
  public theme: any;
  public interaction: any;
  public on: any;
  public off: any;
  private emit: any;

  constructor() {}

  initialize(el, theme: any) {
    console.log('webgl initialize')
    this.projectOver = this.projectOver.bind(this)
    this.projectOut = this.projectOut.bind(this)
    this.scene = new Scene();
    this.theme = theme;
    this.camera = new PerspectiveCamera(45, window.innerWidth/ window.innerHeight);
    this.camera.position.z = 5;
    this.renderer = new WebGLRenderer({ alpha: true });
    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.setClearColor(0xffffff);
    this.renderer.setSize( window.innerWidth, window.innerHeight );
    if (window) {
      this.interaction = new Interaction(this.renderer, this.scene, this.camera);
      this.interaction.setTargetElement(document.body);
    }
    el.current.appendChild( this.renderer.domElement );

    this.indexView = new WebGLIndexPage(this);
    this.indexView.on('project:over', this.projectOver);
    this.indexView.on('project:out', this.projectOut);
    happens(this);
  }

  projectOver(project) {
    this.emit('project:over', project);
  }

  projectOut() {
    this.emit('project:out');
  }

  update() {
    this.indexView.update()
    this.renderer.render( this.scene, this.camera );
  }

  resize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize( window.innerWidth, window.innerHeight );
    const fov = this.camera.fov * (Math.PI / 180)
    const height = 2 * Math.tan(fov / 2) * this.camera.position.z
    const width = height * this.camera.aspect
    this.viewport = { width, height, top: height / 2, left: -width/2 };
    this.indexView.resize();
  }

  onBreakpointChange(breakpoint: number) {
    this.indexView.onBreakpointChange(breakpoint);
  }

  clearThree(obj){
    while(obj.children.length > 0){
      this.clearThree(obj.children[0])
      obj.remove(obj.children[0]);
    }
    if(obj.geometry) obj.geometry.dispose()

    if(obj.material){
      //in case of map, bumpMap, normalMap, envMap ...
      Object.keys(obj.material).forEach(prop => {
        if(!obj.material[prop])
          return
        if(obj.material[prop] !== null && typeof obj.material[prop].dispose === 'function')
          obj.material[prop].dispose()
      })
      obj.material.dispose()
    }
  }


  destroy() {
    this.clearThree(this.scene);
    if(this.renderer){
      this.renderer.forceContextLoss();
      this.renderer.dispose();
      this.renderer=undefined;
    }
  }
}

export default new WebGLApp;
