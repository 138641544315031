import styled from 'styled-components';
import VimeoPlayer from 'react-player/vimeo'
import YouTubePlayer from 'react-player/youtube'
import { Breakpoint } from '../../../data/styles/Breakpoint';
import { minWidth } from '../../../util/style/mixins/respondTo';

export const Container = styled.div`
  display: block;
`;

export const Image = styled.img`
  object-fit: contain;
  margin: 0 auto;
  display: block;
  width: 100%;
`

export const YTPlayer = styled(YouTubePlayer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`

export const VMPlayer = styled(VimeoPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`

export const VideoWrapper = styled.div`
  pointer-events: none;
  position: relative;
  padding-top: ${props => `${140 * props.vwidth / props.vheight}%`}; /* Player ratio: 100 / (1280 / 720) */
`
