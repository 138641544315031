import React, { forwardRef } from "react"
import { TypeHeadingSizes } from '../../common/typography/TypeHeading';
import { TypeParagraphSizes } from '../../common/typography/TypeParagraph';
import { Container, Title, Subtitle, Description, Info } from './styled';

export interface BlockTextProps { className?: string, data: any };

const BlockText = forwardRef((props: BlockTextProps, ref) => {

  return (
    <Container className={props.className}>
      <Title size={TypeHeadingSizes.H4}>{props.data.Title}</Title>
      <Info>
        <Subtitle size={TypeParagraphSizes.M}>{props.data.Subtitle}</Subtitle>
        <Description size={TypeParagraphSizes.S}>{props.data.Description}</Description>
      </Info>
    </Container>
  )
})

export default BlockText;
