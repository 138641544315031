import { useStaticQuery, graphql } from 'gatsby';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import ProjectListing from 'src/components/organisms/ProjectListing'
import Footer from '../../molecules/Footer';
import WebGLApp from '../../webgl/WebGLApp';
import { indexData } from './data';
import { Container } from './styled';
import scroller from 'src/util/scroller';

const IndexPage = forwardRef((props: any, ref) => {

  let el = useRef();
  const projectList = useRef() as any;
  const renderData = indexData(props.data);

  useEffect(() => {
    WebGLApp.indexView.initialize(renderData)
    WebGLApp.indexView.transitionIn()
    scroller.init(el.current);
    return () => {
      scroller.destroy();
      WebGLApp.indexView.transitionOut()
    }
  }, [])

  return (
    <Container data-scroll ref={el}>
      <ProjectListing ref={projectList} projects={renderData} />
      <Footer className='footer' />
    </Container>
  )
})

export default IndexPage;
