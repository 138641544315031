import React, { forwardRef, useContext, useEffect } from "react"
import { ThemeContext } from '../../Layout/Layout';
import VimeoPlayer from 'react-player/vimeo'
import YouTubePlayer from 'react-player/youtube'
import { Container, Image, YTPlayer, VMPlayer, VideoWrapper } from './styled';

export interface AssetProps { className?: string, data: any };


const Asset = forwardRef((props: AssetProps, ref) => {

  const theme = useContext(ThemeContext)

  const playerConfig = {
    youtube: {
      playerVars: { autoplay: true, loop: true, muted: true }
    },
    vimeo: {
      playerOptions: {
        autoplay: true,
        loop: true,
        muted: true
      }
    }
  }

  const getAssetUrl = () => {
    if (props.data.Upload.formats.large) {
      return props.data.Upload.formats.large.url
    }
    if (props.data.Upload.formats.small) {
      return props.data.Upload.formats.small.url
    }
  }

  const getElement = () => {
    if (props.data.Upload) {
      if (/jpg|jpeg|png/.test(getAssetUrl())) {
        return <Image src={`${theme.urls.assetsBase()}${getAssetUrl()}`} />
      }
    } else if (props.data.Video) {
      if (/youtube/.test(props.data.Video.URL)) {
        return (
          <VideoWrapper vwidth={props.data.Video.Width} vheight={props.data.Video.Height}>
            <YTPlayer config={playerConfig} playsinline width='100%' height="100%" muted={true} url={props.data.Video.URL} />
          </VideoWrapper>
        )
      }
      if (/vimeo/.test(props.data.Video.URL)) {
        return (
          <VideoWrapper vwidth={props.data.Video.Width} vheight={props.data.Video.Height}>
            <VMPlayer config={playerConfig} playsinline width='100%' height="100%" muted={true} url={props.data.Video.URL} />
          </VideoWrapper>
        )
      }
    }
  }

  return (
    <Container className={props.className}>
      {getElement()}
    </Container>
  )
})

export default Asset;
