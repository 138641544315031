import React, { forwardRef, useRef, useImperativeHandle, useEffect, useState } from "react"
import { useTranslation } from 'react-i18next';
import { TypeParagraphSizes } from '../../common/typography/TypeParagraph';
import { Container, NavItem, ToggleView } from './styled';
import { onBreakpointChange } from 'src/util/respondTo';
import { Breakpoint } from '../../../data/styles/Breakpoint';

export interface MenuProps { className?: string };

const Menu = forwardRef((props: MenuProps, ref) => {

  const [ isMobile, setIsMobile ] = useState(false);
  const { t } = useTranslation();
  let removeOnBreakpoint;

  useEffect(() => {
    removeOnBreakpoint = onBreakpointChange((breakpoint: number) => {
      if (breakpoint < Breakpoint.M) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    });
    return () => {
      removeOnBreakpoint();
    }
  }, [])

  return (
    <Container className={props.className}>
      {isMobile &&
        <NavItem as={'button'} size={TypeParagraphSizes.M}>{ t('menu') }</NavItem>
      }
      {!isMobile &&
        <>
          <NavItem href='#' as={'a'} size={TypeParagraphSizes.M}>{ t('studio') }</NavItem>
          <NavItem href='#' as={'a'} size={TypeParagraphSizes.M}>{ t('archive') }</NavItem>
        </>
      }
    </Container>
  )
})

export default Menu;
