import React, { forwardRef, useEffect, useRef } from "react"
import { Container } from './styled';
import ProjectHero from 'src/components/organisms/ProjectHero';
import scroller from 'src/util/scroller';
import { caseData } from './data';
import AssetFour from 'src/components/organisms/AssetFour';
import AssetThree from 'src/components/organisms/AssetThree';
import AssetTwo from 'src/components/organisms/AssetTwo';
import BlockText from 'src/components/organisms/BlockText';
import Credits from 'src/components/organisms/Credits';

const CasePage = forwardRef((props: any, ref) => {
  let el = useRef();
  const data = props.data;
  const slug = props.slug;
  const project: any = caseData(data, slug);

  const getComponent = (component, i) => {
    console.log(component.strapi_component)
    switch (component.strapi_component) {
      case 'project-block.four-images':
        return <AssetFour key={i} data={component} />
      case 'project-block.three-images':
        return <AssetThree key={i} data={component} />
      case 'project-block.two-assets':
        return <AssetTwo key={i} data={component} />
      case 'project-block.text-block':
        return <BlockText key={i} data={component} />
      case 'credits.credits':
        return <Credits key={i} data={component} />
      default:
        break;
    }
  }

  const renderComponents = () => {
    return project.Component.map((comp, i) => {
      return getComponent(comp, i);
    })
  }

  useEffect(() => {
    scroller.init(el.current);
    return () => {
      scroller.destroy();
    }
  }, [])
  return (
    <Container data-scroll ref={el}>
      <ProjectHero project={project} />
      {renderComponents()}
    </Container>
  )
})

export default CasePage;



