import React, { forwardRef } from "react"
import { Container, LeftColum, Asset1, Asset2, Asset3, Asset4, RightColum } from './styled';

export interface AssetFourProps { className?: string, data: any };

const AssetFour = forwardRef((props: AssetFourProps, ref) => {

  return (
    <Container className={props.className}>
      <LeftColum>
        <Asset1 data={props.data.Asset1} />
        <Asset3 data={props.data.Asset3} />
      </LeftColum>
      <RightColum>
        <Asset2 data={props.data.Asset2} />
        <Asset4 data={props.data.Asset4} />
      </RightColum>
    </Container>
  )
})

export default AssetFour;
